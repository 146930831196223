import type { LoaderFunctionArgs } from "@remix-run/node";
import { useLoaderData } from "@remix-run/react";
import invariant from "tiny-invariant";
import NotionPage from "~/components/notion/NotionPage";
import { pageLoader } from "~/services/page.server.js";

export default function Home() {
  const { pageMap, pageTree } = useLoaderData<typeof loader>();

  return <NotionPage pageMap={pageMap} pageTree={pageTree} fullWidth={true} />;
}

export async function loader({ request, params }: LoaderFunctionArgs) {
  invariant(params["*"], "Missing path parameter");
  const path = params["*"] == null ? "/" : `/${params["*"]}`;

  return await pageLoader(request, path);
}
